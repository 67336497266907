import React from "react";
import { useEffect } from "react";
import { themeChange } from "theme-change";

const Dropdown = () => {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <div className="flex bg-base-200">
      <select
        data-theme=""
        className="rounded-lg m-4"
        data-choose-theme
      >
        <option value="">Pick a theme</option>
        <option value="halloween">Halloween</option>
        <option value="dracula">Dracula</option>
        <option value="night">Night</option>
        <option value="business">Business</option>
        <option value="acid">Flashbang</option>
        <option value="forest">Forest</option>
        <option value="synthwave">Synthwave</option>
      </select>
    </div>
  );
};

export default Dropdown;
