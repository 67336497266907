import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import BiasCase from "./pages/BiasCase";
import OvCase from "./pages/OvCase";
import ScrollToTop from "./components/ScrollToTop";
import { useEffect } from "react";
import { themeChange } from "theme-change";
import UnderConstruction from "./pages/UnderConstruction";
import BnnvaraCase from "./pages/BnnvaraCase";

function App() {
  useEffect(() => {
    themeChange(false);
  },
    );

  if(!localStorage["alertDisplayed"]) {
    alert("This site is a work in progress and might not function as expected everywhere")
    localStorage["alertDisplayed"] = true
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/BiasCase" element={<BiasCase />} />
        <Route path="/BnnvaraCase" element={<BnnvaraCase />} />
        <Route path="/UnderConstruction" element={<UnderConstruction />} />
        <Route path="/OvCase" element={<OvCase />} />
      </Routes>
    </Router>
  );
}

export default App;
