import React from "react";

const GitHubRedirect = () => {
  return (
    <div className="flex mb-24 justify-center">
      <a href="https://github.com/MerijnVermeulen01/LT3_BiasBrothers">
        <button className="text-base-100 btn btn-primary">
          Check out the code on GitHub here! 
        </button>
      </a>
    </div>
  );
};

export default GitHubRedirect;
