import React from "react";
import HomePage from "../pages/HomePage";
import Footer from "./Footer";
import Dropdown from "./Dropdown";
import RedirectButton from "./RedirectButton";

function Home() {
  return (
    <div data-theme="" className="App bg-base-200 flex flex-col min-h-screen">
      <div className="flex justify-between  flex-row">
        <RedirectButton />
        <Dropdown />
      </div>
      <HomePage className="" />
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
