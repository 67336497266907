import React from "react";
import Card from "./Card";
import biasLogoNoText from "../images/other/biasLogoNoText.png";
import bnnvaraLogo from "../images/other/bnnvaraLogo.jpg";
import HUdark from "../images/other/HULogodark.png";

const Cards = () => {
  return (
    <div className="">
      <div className="flex justify-center items-center sm:space-x-4 sm:m-24 flex-col sm:flex-row  min-h-full">
        <Card
          title="BNNVARA"
          description="This page is under construction as I'm still interning here. Please check back later for updates."
          imageSrc={bnnvaraLogo}
          linkTo="/UnderConstruction"
        />
        <Card
          title="The Bias Brothers"
          description="Developing a web-app for identifying workplace biases!"
          imageSrc={biasLogoNoText}
          linkTo="/BiasCase"
        />
        <Card
          title="HU"
          description="Constructing a Java-based public transport app tailored for the elderly demographic!"
          imageSrc={HUdark}
          linkTo="/OvCase"
        />
      </div>
    </div>
  );
};

export default Cards;