import React from "react";
import Footer from "../components/Footer";
import Backbutton from "../components/Backbutton";
import Carousel from "../components/Carousel";
import GitHubRedirect from "../components/GitHubRedirect";

const BiasCase = () => {
  return (
    <div data-theme="" className="bg-base-200 h-full">
      <Backbutton />
      <div className="flex justify-center m-24">
        <Carousel />
      </div>
      <div className="flex justify-center bg-base-200">
        <article className="prose lg:prose-xl bg-base-200 mb-24 ml-4 mr-4 md:ml-0 md:mr-0">
          <h1 className="text-primary">
            Case Study: Unmasking Cognitive Biases - The BiasBrothers' Web App
          </h1>
          <p>
            In the ever-evolving landscape of professional development, The
            BiasBrothers, a forward-thinking consulting firm specializing in
            diversity, equity, and inclusion, embarked on a transformative
            journey to address the biases lurking within the minds of
            individuals and teams. This case study examines a groundbreaking
            project that aimed to replace their conventional Mural environment
            with a dynamic web application, designed to illuminate and eradicate
            cognitive biases.
          </p>
          <h2>
            <strong className="text-primary">The Challenge:</strong>
          </h2>
          <p>
            The BiasBrothers had long been aware of the challenges posed by
            cognitive biases within organizational settings. They recognized
            that these biases could hamper decision-making, impede creativity,
            and perpetuate inequities. The challenge was to create a tool that
            would empower individuals and teams to identify and confront their
            cognitive biases head-on.
          </p>
          <h2>
            <strong className="text-primary">The Solution:</strong>
          </h2>
          <p>
            The solution was to build a web application that would engage users
            in a series of assignments. These assignments were carefully crafted
            to lead individuals through a process of introspection, gradually
            uncovering the thinking traps they had unknowingly set for
            themselves. The web app utilized interactive elements, real-world
            scenarios, and expertly designed questions to encourage
            self-reflection.
          </p>
          <h2 className="text-primary">Key Features of the Web App:</h2>

          <ol>
            <li>
              <strong className="">Assignment Progression:</strong> Users were
              guided through a series of assignments, each delving deeper into
              their thought processes. This progression allowed for a gradual
              unveiling of biases.
            </li>
            <li>
              <strong>Thinking Trap Identification:</strong> The web app
              employed a proprietary algorithm to analyze user responses. It
              identified patterns of thinking and highlighted potential biases,
              from confirmation bias to groupthink.
            </li>
            <li>
              <strong>Customized Feedback:</strong> After completing
              assignments, users received personalized feedback tailored to
              their specific biases. This feedback included actionable
              strategies to mitigate these biases.
            </li>
            <li>
              <strong>Collaborative Learning:</strong> The BiasBrothers believed
              in the power of collective learning. The web app allowed for team
              participation, fostering open discussions about biases and their
              impact on group dynamics.
            </li>
          </ol>

          <h2 className="text-primary">Project Team:</h2>
          <p>
            Our project team consisted of experts in web development, user
            experience design, and cognitive psychology. I played a pivotal role
            in the project, contributing to the development of the assignment
            content and user interface.
          </p>

          <h2 className="text-primary">Results:</h2>
          <p>
            The launch of the BiasBrothers' web app was met with enthusiasm from
            clients and participants. Over the course of several months, the app
            led to several key outcomes:
          </p>
          <ol>
            <li>
              <strong>Increased Self-Awareness: </strong>Users reported a
              heightened awareness of their cognitive biases and thinking traps,
              leading to more conscious decision-making.
            </li>
            <li>
              <strong>Improved Team Dynamics:</strong>Teams using the app
              experienced improved collaboration, as members were better
              equipped to navigate biases that had previously hindered their
              interactions.
            </li>
            <li>
              <strong>Measurable Bias Reduction:</strong>The app's data
              analytics showed a significant reduction in certain biases,
              demonstrating its effectiveness as a tool for personal and
              professional growth.
            </li>
          </ol>
          <h2 className="text-primary">Conclusion:</h2>
          <p>
            The BiasBrothers' web app project marked a significant step forward
            in the ongoing journey towards inclusivity and equity in the
            workplace. By leveraging technology and psychology, the company
            created a tool that empowered individuals and teams to confront and
            conquer their cognitive biases. This innovative solution has not
            only enhanced professional development but also paved the way for
            more inclusive, equitable, and effective organizations.
          </p>
        </article>
      </div>
      <div>
        <GitHubRedirect />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BiasCase;
