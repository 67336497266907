import React from "react";


const RedirectButton = () => {
  return (
    <div>
      <a href="https://www.games.shawnod.com">
        <button className=" text-base-100 btn btn-primary m-4">
          Check out my other site!
        </button>
      </a>
    </div>
  );
};

export default RedirectButton;
