import React from "react";
import Footer from "../components/Footer";
import Backbutton from "../components/Backbutton";

const OvCase = () => {
  return (
    <div data-theme="" className="bg-base-200 h-screen">
      <Backbutton />
      <div className="flex justify-center"></div>
      <div className="flex justify-center bg-base-200">
        <article className="prose lg:prose-xl bg-base-200 mb-24 ml-4 mr-4 md:ml-0 md:mr-0">
          <h1 className="text-primary">
            Case Study: Elderly-Friendly Public Transport Application
          </h1>
          <h2>
            <strong className="text-primary">Introduction:</strong>
          </h2>
          <p>
            In the realm of academic exploration and software development, our
            team embarked on a transformative journey during our school years to
            address the challenges faced by the elderly in using public
            transportation. This case study delves into the development of a
            public transport application aimed at making transportation more
            accessible to the elderly population, using Java, SceneBuilder, and
            XML as our primary tools.
          </p>
          <h2>
            <strong className="text-primary">The Challenge:</strong>
          </h2>
          <p>
            Our team recognized that the elderly population often faces
            difficulties when using public transportation due to complex
            interfaces and small buttons on existing applications. To address
            this challenge, we were tasked with creating an application
            specifically tailored to the needs of the elderly, simplifying the
            user interface and making essential functions more accessible.
          </p>
          <h2>
            <strong className="text-primary">The Solution:</strong>
          </h2>
          <p>
            Our solution was to design and develop a public transport
            application with a user-friendly interface, focusing on minimizing
            cognitive load and enhancing accessibility for the elderly. To
            achieve this, we used Java for backend logic, SceneBuilder for UI
            design, and XML for data management.
          </p>
          <h2 className="text-primary">
            Key Features of the Public Transport Application:
          </h2>

          <ol>
            <li>
              <strong>Simplified User Interface:</strong> We removed unnecessary
              buttons and options, ensuring a clutter-free interface with
              prominently displayed and large buttons for essential functions
              like checking schedules, planning routes, and purchasing tickets.
            </li>
            <li>
              <strong>Accessibility Enhancements:</strong> To accommodate
              elderly users with potential vision impairments, we incorporated
              adjustable text sizes, high-contrast themes, and audio feedback
              for navigation. Clear Route Planning: The application featured an
              intuitive route planner with step-by-step instructions and
              real-time updates on bus or train locations, making it easy for
              users to navigate public transportation.
            </li>
            <li>
              <strong>Clear Route Planning:</strong> The application featured an
              intuitive route planner with step-by-step instructions and
              real-time updates on bus or train locations, making it easy for
              users to navigate public transportation.
            </li>
            <li>
              <strong>Purchase Assistance:</strong> For users who wished to buy
              tickets through the app, we implemented a simplified ticket
              purchase process with clear pricing information and payment
              options.
            </li>
          </ol>

          <h2 className="text-primary">Project Team:</h2>
          <p>
            Our project team comprised talented individuals with expertise in
            Java development, user interface design, and XML data management.
            [Your Name] played a crucial role in designing the user interface
            and ensuring an intuitive user experience, focusing on elderly
            users' specific needs.
          </p>

          <h2 className="text-primary">Results:</h2>
          <p>
            Our project team comprised talented individuals with expertise in
            Java development, user interface design, and XML data management.
            [Your Name] played a crucial role in designing the user interface
            and ensuring an intuitive user experience, focusing on elderly
            users' specific needs.
          </p>

          <ol>
            <li>
              <strong>Enhanced Understanding: </strong>Throughout the project,
              our team gained a deep understanding of the unique challenges
              faced by elderly individuals when using technology, particularly
              in the context of public transportation.
            </li>
            <li>
              <strong>User-Centered Design:</strong> We honed our skills in
              user-centered design by prioritizing simplicity, accessibility,
              and usability to cater to the needs of the elderly demographic.
              Technical Proficiency: Our team gained proficiency in Java,
              SceneBuilder, and XML, valuable skills
            </li>
            <li>
              <strong>Empathy and Inclusivity:</strong> This project instilled
              in us a sense of empathy and the importance of designing solutions
              that include and empower marginalized groups, such as the elderly.
            </li>
          </ol>

          <h2 className="text-primary">Conclusion:</h2>
          <p>
            While the public transport application developed by our team was not
            deployed in the real world, the experience was invaluable in terms
            of learning and personal growth. It emphasized the importance of
            creating technology solutions that address the specific needs of
            disadvantaged groups, such as the elderly, and highlighted the
            significance of user-centered design principles in software
            development. Our journey in building this application, even within
            an academic context, contributed to our broader understanding of
            inclusivity and technology's potential to improve the lives of
            individuals from all walks of life.
          </p>
        </article>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default OvCase;
