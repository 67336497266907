import React from "react";
import mock from "../images/mock/mock1.png";
import mock2 from "../images/mock/mock2.png";
import mock3 from "../images/mock/mock3.png";
import mock5 from "../images/mock/mock5.png";
import mock6 from "../images/mock/mock6.png";
import mock7 from "../images/mock/mock7.png";
import mock8 from "../images/mock/mock8.png";
import mock9 from "../images/mock/mock9.png";
import mock10 from "../images/mock/mock10.png";
import mock11 from "../images/mock/mock11.png";

const Carousel = () => {
  return (
    <div className="carousel carousel-center p-2 space-x-1 bg-base-300 rounded-box shadow-xl w-full h-full md:w-full md:h-full lg:w-2/4">
      <div className="carousel-item container">
        <img src={mock11} className="container rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock10} className=" rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock8} className=" rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock9} className="rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock7} className="rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock6} className="rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock5} className="rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock5} className="rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock3} className="rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock2} className="rounded-box" alt="shoe" />
      </div>
      <div className="carousel-item container">
        <img src={mock} className="rounded-box" alt="shoe" />
      </div>
    </div>
  );
};

export default Carousel;
