import React from "react";
import { Link } from "react-router-dom";

const Card = ({ title, description, imageSrc, linkTo }) => {
  return (
    <div className="card w-96 bg-base-100 mt-24 sm:mt-0 shadow-xl">
      <figure>
        <img className="" src={imageSrc} alt="Shoes" />
      </figure>
      <div className="card-body p-6 sm:h-72  flex flex-col justify-between">
        <h2 className="card-title">{title}</h2>
        <p>{description}</p>
        <div className="card-actions justify-end min-h-fit">
          <Link to={linkTo}>
            <button className="btn btn-primary mt-2 text-base-100">
              Read more
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;