import React from "react";
import { Typewriter } from "react-simple-typewriter";



const Frontpage = () => {
  return (
    <div className="">
      <div className="flex justify-center">
        <div className="w-64 mt-12 ">
          <img
            className=" border-8 border-primary rounded-full sm:scale-150 sm:mb-20 mb-12"
            src={
              "https://shawnod.com/static/media/bnnfotoCopy.bb2d626f8997ce4e84bb.jpg"
            }
            alt="headshot of Shawn O'Donoghue"
          />
        </div>
      </div>
      <h1 className="font-mono flex justify-around">
        Hi, my name is Shawn O'Donoghue and I'm
      </h1>
      <span className="font-mono flex justify-center text-primary">
        <Typewriter
          words={[
            "a Software Developer",
            "a UX Designer",
            "a UX Coach",
            "an Agile Coach",
            "a Scrum Master",
            "a Tech Enthusiast",
          ]}
          loop={true}
          cursor={true}
          cursorStyle="_"
          typeSpeed={60}
          deleteSpeed={30}
          delaySpeed={1500}
        />
      </span>
    </div>
  );
};

export default Frontpage;
