import React from "react";
import BackButton from "../components/Backbutton";

const UnderConstruction = () => {
  return (
    <div className=" min-h-screen">
      <BackButton />
      <div className="mt-96 flex justify-center">
        <div className="border-8 border-black bg-gradient-repeating bg-base-100 p-24 rounded-2xl text-slate-200z">
          <div className=" bg-black p-6 rounded-2xl text-slate-100">
            <h1 className="text-4xl mb-4">Under Construction</h1>
            <p className="text-xl">
              This page is currently being worked on. <br /> Please check back
              soon for updates.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
