import React from "react";
import Cards from "../components/Cards";
import Frontpage from "../components/Frontpage";

function HomePage() {
  return (
    <div className="App bg-base-200">
      <Frontpage />
      <Cards />
    </div>
  );
}

export default HomePage;
